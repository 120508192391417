.form {
  margin: auto;
  margin-top: 0;
  margin-bottom: 100px;
  padding: 20px;
  max-width: 25rem;
  background-color: #1a1a1a;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.formTitle {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ff03d5;
}

.formLabel {
  display: block;
  font-size: 1.0rem;
  margin-bottom: 7px;
  color: #ffffff;
  text-align: left;
  font-weight: 600;
}

.formInput {
  background-color: black;
  color: white;
  border: none;
  font-family: 'Montserrat', sans-serif;
  border: solid 1px rgba(3, 83, 255, 0.7);
  border-radius: 10px;
  font-size: 17px;
  padding: 10px;
  margin-bottom: 25px;
  width: 90%;
  transition: all 0.3s ease;
}

.formInput:focus {
  outline: none;
  border-color: #ff03d5;
  box-shadow: 0px 0px 15px rgba(255, 3, 213, 1);
}

#formButton {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 600;
  padding: 10px 20px;
  background-color: #ff03d5;
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

#formButton:hover {
  background-color: #ff47e6;
  box-shadow: 0px 0px 8px rgba(255, 3, 213, 0.5);
}

#bio {
  resize: none;
  height: 100px;
  color: bl;
}

#nationality {
  width: 100%;
}

#city {
  width: 100%;
}

#city:disabled {
  color: gray;
}

.image-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-family: Arial, sans-serif;
  margin-bottom: 50px;
}

#file-input {
  display: none; /* Oculta el input real */
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.custom-file-upload:hover {
  background-color: #0056b3;
}

.image-preview {
  margin-top: 10px;
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.image-preview img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.image-preview:hover {
  transform: scale(1.05);
}

@media (width <= 850px) {

  .form {
    max-width: 80%;
  }

}