.App {
  background-image: linear-gradient(to right, #000000, #3533cd);
  background-size: 240rem;
  background-repeat: repeat-x;
  max-width: 120rem;
  margin: auto;
}

.changeLang {
  position: absolute;
  top: 0.8rem;
  right: 1rem;
}

.changeLang button{
  border: none;
  background: none;
  font-size: 1.3rem;
  cursor: pointer;
}

#ownnamediv {
  margin-left: 5vw;
  height: 20vh;
  font-size: 1.15rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}

#orioffdiv {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 35px;
}

.orioffstudio {
  font-size: 7.5rem;
  letter-spacing: -0.5px;
  margin: 0;
  margin-top: -15px;
  margin-left: 10rem;
}

#orioffTitle {
  font-weight: bold;
}

#studio {
  letter-spacing: -1.5px;
}

#portadabuttons {
  margin-left: 10rem;
  display: flex;
  gap: 1.5rem;
  font-size: 25px;
  font-size: 1.55rem;
}

#formButtonPopup {
  margin-bottom: 40px;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 17px;
  font-weight: 500;
  padding: 10px 20px;
  transition: all 0.3s ease;
}

.portadabutton {
  all: unset;
  user-select: none;
  cursor: default;
  background-color: #ffffff;
  color: #191919;
  border-radius: 50px;
  padding: 18px 55px 18px 55px;
  padding: 1rem 3rem 1rem 3rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.portadabutton:hover {
  background-color: #3533cd; 
  color: #ffffff; 
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

#buynow {
  font-weight: bold;
  box-shadow: 0px 0px 12px rgba(255, 3, 213, 0.2);
}

#login {
  cursor: pointer;
  font-weight: 600;
  font-size: 0.85rem;
  padding: 4px 15px 4px 15px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: linear-gradient(to bottom right, #000000, #3533cd);
}

.section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: linear-gradient(to top right, #000000, #3533cd);
}

.titleapps {
  height: 20vh;
  height: 11rem;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 3rem;
  font-weight: 600;
}

#items {
  display: flex;
  gap: 5rem;
  text-align: center;
  max-width: 80vw;
  margin: auto;
  margin-top: 0;
  padding: 20px;
  scrollbar-width: thin;
  scrollbar-color: #f1f1f1 #1d1c50 ;
}

.item {  
  all: unset;
  display: flex;
  flex-direction: column;
  border: 2px solid transparent;
  animation: borderAnimationS 5s infinite; 
  padding: 1.5rem;
  color: #191919;
  min-width: 13rem;
  width: 100%;
  max-width: 24rem;
  border-radius: 10px;
  cursor: default;
  background-color: rgba(53, 51, 205, 0.2);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.6);
  transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

@keyframes borderAnimationS {
  0% {
    border-color: rgba(255, 255, 255, 0); 
  }
  50% {
    border-color: rgba(22, 12, 158, 0.8); 
  }
  100% {
    border-color: rgba(255, 255, 255, 0); 
  }
}

.item:hover {
  transform: scale(1.02);
}

.itemimage {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}

#sectionbutton {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin: auto;
}

.itemtitle {
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.05px;
  color: #ffffff;
}

.itemdesc {
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
  width: 95%;
  font-size: 0.95rem;
  letter-spacing: 0.3px;
  color: #ececec;
}

#section1 {
  background-image: linear-gradient(to bottom right, #000000, #3533cd);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4vw;
  flex-wrap: wrap;
}

#section2 {
  background-image: linear-gradient(to top right, #000000, #3533cd);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4vw;
  flex-wrap: wrap;
}

#section3 {
  background-image: linear-gradient(to bottom right, #000000, #3533cd);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4vw;
  flex-wrap: wrap;
}

#sectiontext {
  width: 42%;
}

.sectiontitle {
  font-weight: 400;
  font-size: 55px;
  text-shadow: 0px 4px 15px rgba(255, 255, 255, 0.2);
}

.sectiondesc {
  font-weight: 400;
  font-size: 1.8rem;
  padding-right: 20px;
  color: #ececec;
  text-shadow: 0px 4px 15px rgba(255, 255, 255, 0.1);
}

.sectionimage {
  width: 40%;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.sectionimagerev {
  width: 40%;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.sectionimage:hover, .sectionimagerev:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.1);
}

.footer {
  height: 6%;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 3rem;
  padding-bottom: 2.5rem;
  font-size: 0.9rem;
  color: white;
  background-color:#07061f;
  letter-spacing: 0;
}

.footerContent {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.footerCol {
  margin-left: 0;
  display: flex;
  flex-direction: column;
}

.footerCol p{
  margin-top: 0;
}

.footerTitle {
  font-weight: 600;
}

#myCvFooter {
  display: flex;
  margin-top: 2rem;
  gap: 1rem;
}

#myCvFooter a{
  width: 2rem;
}

#myCvFooter img{
  width: 2rem;
}

:focus {
  outline: none !important;
  animation: borderAnimation 5s infinite; 
  box-shadow: 0px 0px 10px rgba(255, 3, 213, 0.8);
}

@keyframes borderAnimation {
  0% {
    border-color: rgba(255, 255, 255, 0); 
  }
  50% {
    border-color: rgba(255, 3, 213, 0.8); 
  }
  100% {
    border-color: rgba(255, 255, 255, 0); 
  }
}

@media (hover: hover) and (pointer: fine) {
  .item {
    cursor: pointer; /* Cursor visible solo en escritorio */
  }

  .portadabutton {
    cursor: pointer; /* Cursor visible solo en escritorio */
  }
}

@media (width <= 1150px) {

  #items {
    
  overflow-x: auto;
  }


  #sectiontext {
    width: 80vw;
    text-align: center;
  }

  #section1, #section3 {
    flex-direction: column;
  }

  #section2 {
    flex-direction: column-reverse;
  }

  .sectionimage, .sectionimagerev {
    width: 60vw;
    margin-bottom: 10px;
  }

}

@media (width <= 650px) {
  
  .orioffstudio {
    font-size: 80px;
    margin: 0;
    margin-top: -15px;
    margin-left: 10vw;
  }
  
  #portadabuttons {
    margin-left: 25vw;
    margin-right: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
  }

  #items {
    min-height: 60vh;
    flex-direction: column;
    margin: auto;
  }

  .item {
    max-width: 60%;
    margin: auto;
  }

  #sectiontext {
    width: 80vw;
    text-align: center;
  }

  #section1, #section3 {
    flex-direction: column;
  }

  #section2 {
    flex-direction: column-reverse;
  }

  .sectionimage, .sectionimagerev {
    width: 60vw;
    margin-bottom: 10px;
  }

}

@media (width <= 550px) {

  

  #login {
    font-size: 0.75rem;
  }

  #items {
    gap: 14vw;
  }

  .sectiontitle {
    font-size: 45px;
  }
  
  .sectiondesc {
    font-size: 1.5rem;
  }

  .sectionimage, .sectionimagerev {
    width: 60vw;
    margin-bottom: 20px;
  }

  #sectionbutton {
    margin: auto;
    margin-top: 1.5rem;
  }


}

@media (width <= 450px) {

  .footerContent {
    flex-direction: column;
    margin: auto;
  }

  #ownnamediv {
    font-size: 0.9rem;
  }

}