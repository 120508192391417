html {
    background-color: #07061f;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to bottom, #000000, #3533cd, #000000); /* Agregado el color para reflejo */
  background-size: 240rem;
  background-repeat: repeat-x;
  height: 100%;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
