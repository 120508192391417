#orioff {
  background-image: linear-gradient(to bottom right, #000000, #3533cd);
  min-height: auto;
}

#me {
  min-height: auto;
}

.titleAbout {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3rem;
  font-weight: 600;
  max-width: 60%;
  margin: auto;
  border-radius: 10px;
}

#myCv {
  display: flex;
  gap: 10%;
  margin: 1rem 2rem 0 2rem;
}

#myCv a{
  max-width: 10rem;
}

.aboutGroupIntro {
  border-radius: 10px;
  padding: 20px;
  width: 80%;  
}

.aboutGroup {
  background-color: rgba(53, 51, 205, 0.2);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  width: 80%;  
  margin-bottom: 3rem;
}

#aboutMeGroup {
  margin-bottom: 1rem;
}

.structureDiagramUrl {
  display: flex;
  margin-bottom: 1rem;
  user-select: none;
  cursor: default;
  width: 50%;
  border-radius: 15px;
}

.structureDiagram {
  margin: auto;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

#structureImage {
  transition: scale ease 0.1s;
}


#githubCont {
  border-radius: 50%;
}

#githubLogo {
  border-radius: 50%;
}


.structureDiagram:hover {
  scale: 1.01;
}

.aboutContent {
  font-size: 1.15rem;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: center;
}

.sectionTitle {
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 2.5rem;
}

.serviceTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 15px;
  text-align: center;
}

.serviceList {
  list-style-type: disc;
  font-weight: 400;
  margin-left: 20px;
  margin-bottom: 1.2rem;
}

.serviceName {
  font-weight: bold;
}

.serviceList li {
  font-size: 1.1rem;
  margin-bottom: 0.6rem;
}

.arrow-container {
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  margin-top: 2rem;
  border-radius: 10px;
  transition: background-color ease 0.3s;
  animation: boxShadowAnimation 5s infinite; 
}

@keyframes boxShadowAnimation {
  0% {
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0); 
  }
  50% {
    box-shadow: 0px 0px 10px rgba(255, 3, 213, 0.3); 
  }
  100% {
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0); 
  }
}

.arrow-container:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.additionalInfo {
  margin-top: 10px;
  font-size: 1.0rem;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  height: 0; 
  margin: 0;
  overflow: hidden;
  opacity: 0;
  transition: height 0.6s ease, opacity 0.3s ease;
}

.additionalInfo.open {
  height: auto; 
  padding: 10px;
  padding-bottom: 0px;
  opacity: 1;
}

.additionalInfoList {
  list-style-type: disc;
  font-weight: 400;
  margin-bottom: 1.2rem;
  margin-right: 1.8rem;
  text-align:justify;
  font-weight: 400;
}

.additionalInfoList li {
  font-size: 1.05rem;
  margin-bottom: 0.6rem;
}

.custom-link {
  color: #007BFF;
  text-decoration: none; 
  font-weight: bold;
}

.custom-link:hover {
  color: #0056b3; 
  text-decoration: underline; 
}

.cloudLogo {
  height: 2rem;
  vertical-align:bottom;
}

@media screen and (max-width: 1100px) {

  .titleAbout {
    max-width: none
  }
  
}

@media screen and (max-width: 650px) {
  .structureDiagramUrl {
    width: 80%;
  }
  

}