.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: #ffffff;
}

.popup-overlay h2{
  font-size: 19px;
}

.popup-overlay p{
  font-size: 13px;
  font-weight: 500;
}

.popup-content {
  padding: 20px;
  border-radius: 8px;
  max-width: 80%; 
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1020;
}

.close-button {
  background: transparent;
  color: #ffffff;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px; 
}

.popupEventInfo {
  margin-left: 15px;
  text-align: left;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.popup-overlay img{
  height: 180px;
  width: 180px;
  border-radius: 4px;
}

.form {
  max-width: "60vw"
}

#signUpForm {
  width: 40vw;
  margin-bottom: 0;
}

@media (max-width: 600px) {
  .popup-content {
      max-width: 75%; /* Ajustar para pantallas pequeñas */
  }

  #signUpForm {
    width: 60vw;
    margin-bottom: 0;
  }

  .popupEventInfo {
    text-align: center;
    margin-left: 0px;
  }

  .popupEvent {
    flex-direction: column;
    align-items: center;
  }

}